.input {
  width: 100%;

  .modeSelect {
    width: 100%;

    select {
      font-size: 1.5em;
      font-weight: 600;
      width: 100%;
    }
    margin-bottom: 1em;
  }

  textarea {
    width: calc(100% - 2px - 2em);
    padding: 1em;
    min-height: 300px;
  }
}
