.toggle {
  border: thin solid black;
  cursor: pointer;
  display: inline-block;
  padding: 0.25em 0.5em;
  user-select: none;
  background-color: white;

  margin-left: 0.5em;

  &:first-child {
    margin-left: 0;
  }

  &.active {
    font-weight: 700;
    background-color: #aaa;
  }

  &:hover:not(.active) {
    background-color: #ccc;
  }
  &:active {
    background-color: #aaa;
  }
}

.dropdown {
  border: thin solid black;
  cursor: pointer;
  display: inline-block;
  padding: 0.25em 0.5em;
  user-select: none;
  background-color: white;

  margin-left: 0.5em;

  &:first-child {
    margin-left: 0;
  }
}

.button {
  border: thin solid black;
  cursor: pointer;
  display: inline-block;
  padding: 0.25em 0.5em;
  user-select: none;
  background-color: white;

  margin-left: 0.5em;

  &:first-child {
    margin-left: 0;
  }

  &:hover:not(.active) {
    background-color: #ccc;
  }
  &:active {
    background-color: #aaa;
  }
}
